<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Litters</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Litters"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto"> </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="tableHeaders"
            :items="litters"
            no-data-text="No Litters"
            :items-per-page="-1"
          >
            <template v-slot:item.dob="{ item }">
              {{ formatDate(item.dob) }}
            </template>

            <template v-slot:item.pupp="{ item }">
              {{ item.puppies.length }}
            </template>

            <template v-slot:item.boys="{ item }">
              {{ countPuppiesGender(item, "M").length }}
            </template>

            <template v-slot:item.girls="{ item }">
              {{ countPuppiesGender(item, "F").length }}
            </template>

            <template v-slot:item.total_available="{ item }">
              {{
                item.puppies.length - item.applications.length > 0
                  ? item.puppies.length - item.applications.length
                  : 0
              }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                x-small
                depressed
                class="mr-2"
                color="green lighten-4 green--text"
                :to="{
                  name: 'module-lhl-litters-individual',
                  params: { litterId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>

              <v-btn
                bottom
                x-small
                depressed
                color="red lighten-4 red--text"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      scrollable
      v-model="deleteLitter.dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Archive Litter</v-card-title>
        <v-card-text>Are you sure you wish to archive?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteLitter.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LitterForm ref="litterForm" />
  </div>
</template>

<script>
import LitterForm from "./components/LitterForm";

export default {
  components: {
    LitterForm,
  },

  data() {
    return {
      deleteLitter: {
        dialog: false,
        litter: {},
        loading: false,
      },

      searchTerm: "",
      breadcrumbs: [
        {
          text: "Properties",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],

      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Dob", value: "dob" },
        { text: "Puppies", value: "pupp" },
        { text: "Girls", value: "girls" },
        { text: "Boys", value: "boys" },
        { text: "Available", value: "total_available" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    litters() {
      let litters = this.$store.getters["lhl/litters/all"];

      if (this.searchTerm !== "") {
        litters = litters.filter((c) => {
          const name = c.name.toLowerCase();

          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return litters;
    },
  },

  methods: {
    countPuppiesGender(item, gender) {
      let puppies = item.puppies.filter((c) => c.gender == gender);

      return puppies;
    },

    openDelete(litter) {
      this.deleteLitter.litter = litter;
      this.deleteLitter.dialog = true;
    },

    resetDelete() {
      this.deleteLitter.dialog = false;
      this.deleteLitter.litter = {};
      this.deleteLitter.loading = false;
    },

    saveDelete() {
      this.deleteLitter.loading = true;

      this.$store
        .dispatch("lhl/litters/deleteLitter", {
          appId: this.$route.params.id,
          litterId: this.deleteLitter.litter.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteLitter.loading = false;
        });
    },
  },
};
</script>
